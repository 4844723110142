<!--
 * @Description: 人行道停车运营管理 数据看板 运营统计 parkRunStatistics
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <el-row class="tableTitle">
      <el-tabs v-model="activeName">
        <el-tab-pane label="运营统计"
                     name="first"></el-tab-pane>
        <!-- <el-tab-pane label="实时"
                     name="second"></el-tab-pane> -->
      </el-tabs>
    </el-row>
    <!-- 内容 -->
    <el-row v-if="activeName ==='first'">
      <history />
    </el-row>
    <el-row v-if="activeName ==='second'">
      <!-- <online /> -->
    </el-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import history from './history.vue'
// import online from './online.vue'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    history
    //  online,
  },
  data () {
    // 这里存放数据
    return {
      activeName: 'first'
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  // 方法集合
  methods: {},
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.mainbody {
  height: 100%;
  .tableTitle {
    height: 32px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
</style>